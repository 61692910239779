import React from 'react'
import { graphql } from 'gatsby'

import { getSrc } from "gatsby-plugin-image"

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Hero from '../components/Hero'
import Breadcrumbs from '../components/Breadcrumbs'
import ArticlesRow from '../components/ArticlesRow'


const CategoryTemplate = ({ data }) => {
  const articles = data.articles.edges
  const category = data.category

  return (
    <Layout>
      <Seo
        title={category.name}
        description={category.description || 'nothin’'}
        image={data.category.image ? getSrc(data.category.image.localFile) : null}
        pathname={category.slug}
      />
      <Hero title={category.name} />
      <section className="section">
        <Breadcrumbs category={category} />
      </section>

      <section className="section">
        <div className="container">
          <h1 className="title">{category.name}</h1>
          <ArticlesRow articles={articles} />
        </div>
      </section>
    </Layout>
  )
}

export default CategoryTemplate

export const query = graphql`
  query CategoryTemplateQuery($slug: String!) {
    articles: allStrapiArticle (
      filter: { category: { slug: { eq: $slug } } }
      sort: { order: DESC, fields: created_at }
    ) {
      edges {
        node {
          slug
          sticky
          title
          content
          category {
            name
          }
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600 height: 300)
              }
            }
          }
        }
      }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
      slug
      description
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 640
              height: 300
              transformOptions: {cropFocus: CENTER}
            )
          }
        }
      }
    }
  }
`
