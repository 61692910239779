import React from 'react'
import ArticleLink from './ArticleLink'

const ArticlesRow = ({ articles }) =>(
  <>
    {articles.map((article, i) => {
      if(article.node.sticky){
        return <ArticleLink article={article} key={i} />
      }
      return null
    })}

    {articles.map((article, i) => {
      if(!article.node.sticky){
        return <ArticleLink article={article} key={i} /> 
      }
      return null
    })}
  </>
)

export default ArticlesRow
