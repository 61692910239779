import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'

import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ArticleLink = ({ article }) => {
  // const image = getImage(article.node.images.[0].localFile)
  console.log("ArticleLink.article", article)


  return (
    <div className="box">
      <div className="columns ">

          { article.node.images && article.node.images.length > 0 &&
            <div className="column">
              <GatsbyImage
                image={getImage(article.node.images[0].localFile)}
                alt={article.node.title}
              />
            </div>
          }


        <div className="column">
          <div className="content">
            <h3 className="title is-3">{article.node.title}</h3>
            {article.node.content &&
              <LinesEllipsis
                text={article.node.content}
                maxLine='3'
                className="mb-3"
              />
            }

            <Link
              to={article.node.slug}
              className="button is-info"
            >Lees verder {article.node.name}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArticleLink
